* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #e4e6e9;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
    text-decoration: none;
}

#root {
}

.react-toast-notifications__container {
    font-family: 'roboto';
}

::-webkit-scrollbar {
    width: 3px;
    height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    background: darkgray;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #4db249;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #3da239;
}

body {
    background-color: #e0e0e0;
    height: 100%;

    &.links-underline {
        a:not(.button-link) {
            text-decoration: underline;
        }

        button {
            text-decoration: underline;
        }

        &.language-switcher {
            li {
                text-decoration: underline !important;
            }
        }
    }

}
